@import "../variables";

@mixin ship_list_or_offer_content() {
  content: "";
  position: absolute;
  display: block;
  bottom: -.18rem;
  height: .10rem;
  width: 100%;
  left: 0;
  right: 0;
  background-color: $nib-blue-color;
  transition: transform .2s ease-in;
  transform: scaleX(0);
}

@mixin card_custom_title() {
  color: $nib-blue-color;
  font-size: 20px;
  text-transform: uppercase;
}

@mixin bg_shadow() {
  -webkit-box-shadow: 0 7px 14px 0 rgba(59, 65, 94, .1), 0 3px 6px 0 rgba(0, 0, 0, .07);
  -moz-box-shadow: 0 7px 14px 0 rgba(59, 65, 94, .1), 0 3px 6px 0 rgba(0, 0, 0, .07);
  box-shadow: 0 7px 14px 0 rgba(59, 65, 94, .1), 0 3px 6px 0 rgba(0, 0, 0, .07);
}

@mixin transition($property: all, $duration: .5s, $timingFunction: ease, $delay: 0s) {
  transition: $property $duration $timingFunction $delay;
  -webkit-transition: $property $duration $timingFunction $delay;
  -moz-transition: $property $duration $timingFunction $delay;
  -o-transition: $property $duration $timingFunction $delay;
}
