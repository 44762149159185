@import "animate";
@import "variables";
@import "mixins/custom_mixins";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.prospekt {

  clear: none;

  .card-text {
    clear: none;
  }

  .text-nib {
    color: $nib-blue-color
  }


  .card {
    border-radius: 5px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 0 solid #edf2f9;
  }

  .card, .card-header {
    background-color: #fff;
  }

  .card-header:first-child {
    border-radius: calc(0.313rem - 1px) calc(0.313rem - 1px) 0 0;
  }

  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #ffffff;
    background-color: $nib-blue-color;
  }

  .bg-light {
    background-color: $backgroundLight !important;
  }

  .text-prospekt {
    color: $nib-prospekt-color
  }

  .card-text {
    position: relative;
    font-weight: 400;
    word-break: break-word;
    line-height: 1.2;
    letter-spacing: 1px;
  }

  .react-loading-skeleton {
    line-height: inherit !important;
    border-radius: calc(.25rem - 1px) !important;
  }

  $sizesHeight: 150, 200, 250, 300, 350, 400, 450, 500, 550, 600;
  $sizesType: ("h-": false, "h-sm-": "sm", "h-md-": "md", "h-lg-": "lg");

  @each $sizeType, $minWidth in $sizesType {
    .#{$sizeType} {
      @if $minWidth != false {
        @include media-breakpoint-up($minWidth) {
          @each $sizeHeight in $sizesHeight {
            &#{$sizeHeight} {
              max-height: #{$sizeHeight}px !important;
              min-height: #{$sizeHeight}px !important;
              height: #{$sizeHeight}px !important;
            }
          }
        }
      } @else {
        @each $sizeHeight in $sizesHeight {
          &#{$sizeHeight} {
            max-height: #{$sizeHeight}px !important;
            min-height: #{$sizeHeight}px !important;
            height: #{$sizeHeight}px !important;
          }
        }
      }
    }
  }

  .mt-n7 {
    margin-top: -7rem;
  }

  .mt-n8 {
    margin-top: -8rem;
  }

  .mt-n9 {
    margin-top: -9rem;
  }

  .mt-n10 {
    margin-top: -10rem;
  }

  .mt-n15 {
    margin-top: -15rem;
  }

  .content-title {
    padding: 1.25rem 0 1.25rem 1.25rem;
    background-color: #fff;
    position: relative;
    color: $nib-blue-color;
    font-size: 18px;

    h3 {
      color: #0095DA;
      text-transform: uppercase;
    }

    span {
      position: relative;
      display: inline-block;

      &:before {
        content: "";
        position: absolute;
        display: block;
        bottom: -.25rem;
        height: .14rem;
        width: 5rem;
        left: 0;
        right: 0;
        background-color: $nib-blue-color;
      }
    }
  }

  .card-panel {
    $transitionDuration: .3s !default;
    $transitionName: ease-in !default;

    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-right: -$grid-gutter-width / 2;
    margin-left: -$grid-gutter-width / 2;
    margin-top: -1px;
    position: relative;
    z-index: 1;

    .card {
      z-index: 0;
      min-height: 160px;
      max-height: 160px;
      color: $nib-blue-color;
      cursor: pointer;
      border-radius: 5px;
      border: none !important;

      &:hover, &.active {

        background-color: $nib-blue-color;
        color: #ffffff;
        z-index: 1;

        svg.card-img > g > path {
          fill: #ffffff;
          -webkit-transition: fill $transitionDuration $transitionName;
          -ms-transition: fill $transitionDuration $transitionName;
          transition: fill $transitionDuration $transitionName
        }
      }

      &.active {
        transform: none;
      }

      &:hover {
        z-index: 2;
      }
    }

    .card-img {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      height: 50px;
      margin-bottom: 1rem;
    }

    .card-text {
      text-align: center;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    @include media-breakpoint-down(sm) {

      .card {
        min-height: 100px;
        max-height: 100px;

        .card-body {
          padding: 0;
        }

        .card-img {
          height: 30px;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      .card {
        border-radius: 0 !important;
        border-left-color: #edf2f9 !important;
        border-left-width: 2px !important;
        border-left-style: solid !important;
        border-right-color: #edf2f9 !important;
        border-right-width: 2px !important;
        border-right-style: solid !important;
      }

      .nav-item:first-child .card {
        border-top-left-radius: 5px !important;
        border-top-right-radius: 5px !important;
        border-top-color: #edf2f9 !important;
        border-top-style: solid !important;
        border-top-width: 2px !important
      }

      .nav-item:last-child .card {
        border-bottom-left-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        border-bottom-color: #edf2f9 !important;
        border-bottom-style: solid !important;
        border-bottom-width: 2px !important
      }
    }

    @include media-breakpoint-up(sm) {
      .card {
        @include bg_shadow();
      }
    }

    @include media-breakpoint-up(md) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    @include media-breakpoint-up(lg) {
      .card {

        &:hover {
          -webkit-transition: background-color $transitionDuration $transitionName, transform $transitionDuration $transitionName, color $transitionDuration $transitionName;
          -ms-transition: background-color $transitionDuration $transitionName, transform $transitionDuration $transitionName, color $transitionDuration $transitionName;
          transition: background-color $transitionDuration $transitionName, transform $transitionDuration $transitionName, color $transitionDuration $transitionName;
          transform: scale3d(1.2, 1.2, 1.2);
        }
      }
    }
  }

  .tabs-container {
    @include bg_shadow;
    border-radius: .25rem;
    padding: 1rem $grid-gutter-width / 2;
    background-color: $backgroundLight;
    position: relative;
    z-index: 1;

    .tab-container {
      padding-left: $grid-gutter-width / 2;
      padding-right: $grid-gutter-width / 2;

      @include media-breakpoint-up(md) {
        .nav.nav-pills {
          position: sticky;
          top: 93.63px;
          z-index: 2;
        }
      }

      .tab-content {
        @include bg_shadow();
        position: relative;
        overflow: hidden;
        background-color: #ffffff;
        border-radius: .25rem;
        padding: 1rem;
        z-index: 0;
        min-height: 300px;

        &.is-loading {
          &:before {
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            margin: auto;
            background-image: url("../images/logo/logo.svg");
            height: 100px;
            width: 100px;
            z-index: 2;
            background-repeat: no-repeat;
            -webkit-backface-visibility: visible;
            backface-visibility: visible;
            -webkit-animation-name: flip;
            animation-name: flip;
            -webkit-animation-duration: 2s;
            animation-duration: 2s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
          }

          &:after {
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            background: linear-gradient(180deg, $nib-green-color, $nib-blue-color);
            opacity: .2;
            z-index: 1
          }
        }
      }
    }
  }

  .tab-panel {
    margin-right: -$grid-gutter-width / 2;
    margin-left: -$grid-gutter-width / 2;
    position: relative;
    z-index: 1;

    .list-group {
      .list-group-item {
        @include bg_shadow();
        margin-top: -1px;
        border-top-width: 1px;

        &.active {
          background-color: $nib-blue-color;
          border-color: $nib-blue-color;
          color: #ffffff;

        }
      }
    }

    .our-advantages {

      .nav-item {
        .nav-link {
          transition: all 0.4s ease-in-out;
          -moz-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;

          &.active {
            background-color: $nib-blue-color;
            border-color: $nib-blue-color;
            color: #ffffff;
            @include bg_shadow();
          }
        }
      }
    }
  }

  .tp-loader {
    top: 50%;
    left: 50%;
    z-index: 10000;
    position: absolute;
  }

  .tp-loader.spinner0, .tp-loader.spinner5 {
    background-image: url(../images/logo/logo.svg);
    background-repeat: no-repeat;
  }

  .tp-loader.spinner0 {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    animation: rotateplane 1.2s infinite ease-in-out;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
  }

  .tp-loader.spinner0 {
    background-position: center center;
  }

  .tp-loader.spinner5 {
    background-position: 10px 10px;
    background-color: #fff;
    margin: -22px;
    width: 44px;
    height: 44px;
    border-radius: 3px;
  }

  .card-parallax {
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;

    .lazyload-wrapper {
      display: block !important;
    }

    p {
      margin: 0
    }

    @include media-breakpoint-up(md) {
      padding-top: 2rem;
      padding-bottom: 2rem;
      /*.card {
        position: sticky;
        top: 6rem;
        margin-bottom: 1rem;
      }*/
    }

    .card {
      @include bg_shadow;
      border-radius: .25rem;
    }

    .card-img:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, $nib-green-color, $nib-blue-color);
      opacity: .2;
      z-index: 1;
    }

    .card-img-top {
      position: relative;
    }

    .card-img-overlay {
      z-index: 3;

      .card-img-content {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
        height: 100%;
        -ms-flex-pack: center !important;
        justify-content: center !important;

        .card-title {
          font-size: 40px;
          color: $nib-blue-color;
          line-height: 1.2;
          font-weight: 800;
          text-shadow: 2px 0 4px #f5f5f5;
          letter-spacing: 2px;
          text-transform: uppercase;
          word-break: break-word;
        }
      }
    }

    .card-text {
      color: $nib-prospekt-color;

      &.p {
        span {
          display: inline-block;
          padding-top: 25px;
          padding-left: 18px;
          padding-right: 18px;
          float: left;

          svg {
            width: 26px;
            height: 22px;
          }
        }
      }


      + .card-text {
        margin-top: 1rem
      }
    }

    .card-link {
      color: $nib-prospekt-color;
      display: inline-block;
      padding: 2px 5px;
      text-align: center;
      border: 2px solid $nib-prospekt-color;
      margin-right: 2px;
      position: relative;
      opacity: 1;
      line-height: 29px;
      transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;

      &:hover {
        border-color: transparent;
        border-bottom-color: $nib-prospekt-color;
        transition: border .2s ease-in-out;
      }

      svg path {
        fill: $nib-prospekt-color;
      }
    }

    .content-title {
      font-size: 24px;
      line-height: 1.2;
      font-weight: 800;
      letter-spacing: 2px;
      text-transform: uppercase;
      word-break: break-word;
      padding-top: 0;

      span {
        color: $nib-prospekt-color;

        &:before {
          background-color: $nib-prospekt-color;
        }
      }
    }
  }

  .prospekt-panel {
    position: relative;
    display: flex;
    border-bottom: 2px solid #00AEEF;

    .button {
      background-color: #00A651;
      padding: 8px 15px 8px 15px;
      color: #ffffff;

      &.primary {
        background-color: #0072BC;
      }

      + .button {
        margin-left: .25rem;
      }
    }
  }

  .wrapper- {

    &carousel {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
      }
    }

    &content, &tab {

      .content-title h3 {
        color: #0095DA;
        text-transform: uppercase;
      }

      @include media-breakpoint-up(md) {
        .row-columns {
          column-count: 2;
          column-gap: $grid-gutter-width / 2;
          orphans: 1;
          widows: 1;
        }
      }

      .card-col .card {
        margin-bottom: 1rem;
      }

      .card-col:last-child .card {
        margin-bottom: 0
      }

      .card {

        position: relative;
        overflow: hidden;
        @include bg_shadow();
        border: none !important;

        img {
          -o-transform: scale(1.1);
          -ms-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
          transition: transform 1s ease-in-out;
          -moz-transition: -moz-transform 1s ease-in-out;
          -webkit-transition: -webkit-transform 1s ease-in-out;
          -o-transition: -o-transform 1s ease-in-out;
        }

        &:hover img {
          -o-transform: scale(1);
          -ms-transform: scale(1);
          -moz-transform: scale(1);
          -webkit-transform: scale(1);
          transform: scale(1);
        }

        .card-img-overlay {
          padding: 20px 25px 30px;

          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: opacity 0.3s ease-in-out, top 0.5s ease-in-out;
            -moz-transition: opacity 0.3s ease-in-out, top 0.5s ease-in-out;
            -webkit-transition: opacity 0.3s ease-in-out, top 0.5s ease-in-out;
            -o-transition: opacity 0.3s ease-in-out, top 0.5s ease-in-out;
            opacity: .4;
            background: linear-gradient(180deg, $nib-green-color, $nib-blue-color);
          }
        }

        .card-title, .card-sub-title {
          top: 0;
          margin-bottom: 4px;
          text-transform: uppercase;
          font-weight: 700;
          color: #ffffff;
          margin-left: 10px;
          transition: all 0.4s ease-in-out;
          -moz-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          position: relative;
        }

        h4.card-title {
          font-size: 18px;
          line-height: 24px;
          letter-spacing: normal;

          a {
            color: #ffffff;
          }
        }

        .card-sub-title {
          font-size: 14px;
          line-height: 16px;
          letter-spacing: normal;

          a {
            color: #ffffff;
          }
        }

        &:hover .card-title, &:hover .card-sub-title {
          top: 10px;
        }

        &:hover .card-img-overlay:before {
          opacity: 0;
          //top: -70%;
          //transform: translateY(-70%);

        }

        .card-links {
          position: absolute;
          bottom: 20px;
          right: 20px;
          text-align: right;
          width: 100%;

          a, span {
            display: inline-block;
            width: 32px;
            height: 32px;
            color: #fff;
            text-align: center;
            border: 2px solid rgba(255, 255, 255, 0.2);
            margin-right: 2px;
            position: relative;
            opacity: 0;
            top: 0;
            line-height: 29px;
            transition: all 0.4s ease-in-out;
            -moz-transition: all 0.4s ease-in-out;
            -webkit-transition: all 0.4s ease-in-out;
            -o-transition: all 0.4s ease-in-out;

            &:hover {
              border-color: rgba(255, 255, 255, 0.4);
            }

            i {
              font-size: 10px;
            }

            &:last-child {
              -webkit-transition-delay: 0.2s;
              -moz-transition-delay: 0.2s;
              -o-transition-delay: 0.2s;
              transition-delay: 0.2s;
            }
          }
        }

        &:hover .card-links a, &:hover .card-links span {
          opacity: 1;
        }
      }
    }

    &portfolio {

      margin-top: 90px;
      margin-bottom: 3rem;

      .nav-item {
        margin-bottom: 1.5rem;

        .nav-link {
          background-color: $nib-green-color;
          color: #ffffff;
          padding: 8px 15px 8px 30px;
          position: relative;

          &.active, &:hover, &:focus {
            background-color: $nib-blue-color;
          }

          svg {
            position: absolute;
            left: 10px;
            top: 50%;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            -o-transform: translate(0, -50%);
            transform: translate(0, -50%);
          }
        }

        + .nav-item {
          margin-left: .25rem
        }
      }

      .content-title h3 {
        color: #0095DA;
        text-transform: uppercase;
      }

    }

    &container {

      .container-image {
        width: 100%;
        margin-bottom: $grid-gutter-width / 2;
        float: left;

        @include media-breakpoint-up(md) {
          margin-right: $grid-gutter-width;
          width: 35%;
        }

        .card, .card-img {
          z-index: 1;
        }
      }

      .container-text {
        display: inline;
      }

      .content-title {
        background-color: transparent;
      }
    }
  }

  .portfolio {

    &-row {
      display: flex;
      flex-wrap: wrap;

      .portfolio-title {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-align: center !important;
        align-items: center !important;
      }

      .portfolio-title, .portfolio-text {
        padding: $grid-gutter-width / 2 1rem;
        background-color: #fff;
        color: #000;
        border-bottom: 1px solid #CDCED0;
      }

      @include media-breakpoint-up(md) {
        .portfolio-text {
          border-left: 1px solid #CDCED0;
        }
      }

      &:first-child .portfolio-title, &:first-child .portfolio-text {
        border-top: 2px solid #D6D7D8;
      }

      &.info {

        .portfolio-title {
          background: #E1F4FD;
          color: #2574BD;
          font-weight: 600;
          border-bottom: 2px solid #F9FDFE
        }

        &:first-child .portfolio-title, &:first-child .portfolio-text {
          border-top: 2px solid #BDE7FB;
        }

        .portfolio-text {
          border-left: 0;
          border-bottom: 2px solid #BDE7FB;
        }
      }
    }

    &-quote {
      padding: $grid-gutter-width/2 1rem;
      font-style: italic;
      font-size: .9rem;
      color: $nib-prospekt-color
    }

    &-header {
      display: flex;
      justify-content: center;
      font-weight: 600;
      padding: $grid-gutter-width / 2 1rem;

      &.info {
        background: #E1F4FD;
        color: #2574BD;
        border-top: 2px solid #BDE7FB;
        border-bottom: 1px solid #CDCED0;
      }

      &.primary {
        background: #E6E7E8;
        color: #000;
        border-bottom: 1px solid #CDCED0;
        border-top: 2px solid #CCCDCF;
      }
    }
  }

  &.admin {

    display: flex;
    flex-wrap: wrap;
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;

    .card-img {
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 5px;
    }

    .skeleton {
      background-color: #eee;
      background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
      background-size: 200px 100%;
      background-repeat: no-repeat;
      border-radius: 4px;
      display: inline-block;
      line-height: 1;
      width: 100%;
      -webkit-animation: animation-16jpnkj 1.2s ease-in-out infinite;
      animation: animation-16jpnkj 1.2s ease-in-out infinite;
    }

    @-webkit-keyframes animation-16jpnkj {
      0% {
        background-position: -200px 0;
      }
      100% {
        background-position: calc(200px + 100%) 0;
      }
    }
    @keyframes animation-16jpnkj {
      0% {
        background-position: -200px 0;
      }
      100% {
        background-position: calc(200px + 100%) 0;
      }
    }

    .wrapper-tab {
      box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
      position: relative;
      overflow: hidden;
      background-color: #ffffff;
      border-radius: .25rem;
      padding: 1rem;
      z-index: 0;
      min-height: 300px;
      margin: 0 auto;
      width: 100%;
      font-size: 1rem;
      @include media-breakpoint-up(lg) {
        /*flex: 0 0 65%;
        max-width: 65%;*/
        /*max-width: 1232px;*/
        max-width: 1042.5px;
      }
    }
  }

  .photo-gallery {
    overflow: hidden;
    position: relative;
    @include media-breakpoint-down(md) {
      margin-top: 1rem;
    }


    .photo-content {
      .slick-list, .slick-track {
        border-radius: 4px;
        margin-bottom: 15px;
        height: 175px;

        @media (min-width: 360px) {
          height: 190px;
        }
        @media (min-width: 380px) {
          height: 200px;
        }
        @media (min-width: 420px) {
          height: 220px;
        }
        @media (min-width: 480px) {
          height: 250px;
        }
        @include media-breakpoint-up(sm) {
          height: 300px;
        }
        @include media-breakpoint-up(md) {
          height: 350px;
        }
        @include media-breakpoint-up(lg) {
          height: 410px;
        }
      }

      .slick-slide {
        height: 100% !important;
        background: #fff;


        img {
          width: auto;
          max-height: 410px;
          max-width: 100%;
          height: auto;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          border-radius: 4px;
        }
      }
    }

    .photo-indicators {
      .slick-track {
        position: static;
        width: 100%;
        margin-left: 0;
        left: 15px;
        text-align: center;
        margin-bottom: 0;
      }

      .slick-slide {
        width: 90px !important;
        height: 60px !important;
        border: none;
        margin: 2px;
        border-radius: 3px;
        overflow: hidden;
        position: relative;
        text-indent: inherit;
        outline: none !important;
        box-sizing: content-box;

        &:hover {
          cursor: grab;
        }

        &:before {
          position: absolute;
          top: -10px;
          left: 0;
          display: inline-block;
          width: 100%;
          height: 10px;
          content: "";
        }

        &:after {
          position: absolute;
          bottom: -10px;
          left: 0;
          display: inline-block;
          width: 100%;
          height: 10px;
          content: "";
        }

        img, .react-loading-skeleton, .lazyload-wrapper {
          width: 90px !important;
          height: 60px !important;
          -webkit-transition: all .3s;
          -moz-transition: all .3s;
          -ms-transition: all .3s;
          -o-transition: all .3s;
          transition: all .3s;
          filter: grayscale(1);
          -webkit-filter: grayscale(1);
          border-radius: 3px;
        }

        &:hover img, &.slick-current img, &:hover .react-loading-skeleton, &.slick-current .lazyload-wrapper {
          filter: grayscale(0);
          -webkit-filter: grayscale(0);
        }
      }
    }
  }

  .inner-page {
    height: 360px;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: 0;

    @include media-breakpoint-down(sm) {
      height: 220px;
    }
    @include media-breakpoint-down(md) {
      height: 280px;
    }

    &.about-banner {
      background-image: url("../images/carousel_1.jpg");

      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        background: linear-gradient(180deg, $nib-green-color, $nib-blue-color);
        opacity: .6;
        height: 100%;
        width: 100%;
      }
    }

    .banner-text {
      z-index: 2;
      padding-top: 200px;
      color: #fff;

      @include media-breakpoint-down(sm) {
        padding-top: 135px;
      }

      @include media-breakpoint-down(md) {
        padding-top: 165px;
      }

      h1.page-title {
        font-size: 48px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 0;
        color: #ffffff;
        @include media-breakpoint-down(sm) {
          font-size: 22px;
        }
        @include media-breakpoint-down(md) {
          font-size: 36px;
        }
      }

      p.page-breadcrumb {
        margin-top: 10px;
        font-size: 18px;
        @include media-breakpoint-down(sm) {
          font-size: 12px;
          margin-top: 8px;
        }
        @include media-breakpoint-down(md) {
          margin-top: 6px;
          font-size: 16px;
        }

        a {
          color: #ffffff;
        }

        span.current, a:hover {
          color: #ffffff;
          text-decoration: underline;
        }
      }
    }
  }

  .symbol-custom {
    &.FourPointedCusp {
      background-image: url("../images/symbolCustomFourPointedCusp.png");
      position: relative;
      width: 18px;
      height: 18px;
      display: inline-block;
      padding: 0;
      background-size: 18px 18px;
      background-repeat: no-repeat;
      vertical-align: text-bottom;
    }
  }


  .lazyload-wrapper {
    display: flex !important;

    span {
      width: 100%;
    }
  }
}
