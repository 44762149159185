@import "../variables";
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";

@mixin animation($duration: 2s) {
  -webkit-animation-duration: $duration;
  -moz-animation-duration: $duration;
  animation-duration: $duration;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}

@mixin animationName($name) {
  -webkit-animation-name: $name;
  -moz-animation-name: $name;
  animation-name: $name;
}

.carousel-animated {

  background: #fff;

  .carousel-item {
    display: block !important;
    visibility: hidden;
    opacity: .4;
    transition: all 0s ease 0s;
    transform: none;
    @include animation();
    -webkit-tap-highlight-color: transparent;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 750px;
    z-index: 0;

    &.active {
      visibility: visible;
    }

    @include media-breakpoint-down(md) {
      height: 550px;
    }

    .slider-container {
      width: 100%;
      height: 750px;
      z-index: 0;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important;
      @include animation();

      @include media-breakpoint-down(md) {
        height: 550px;
      }

      .slider-cover {
        width: 70%;
        position: relative;
        height: 750px;
        z-index: -1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        overflow: hidden;

        img {
          object-fit: cover;
          height: 750px;
          width: 100%;
        }

        @include media-breakpoint-down(md) {
          height: 550px;
          img {
            height: 550px;
          }
        }

        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: .4;
          background: linear-gradient(180deg, $nib-green-color, $nib-blue-color);
        }

        @include media-breakpoint-down(md) {
          width: 100%;
          z-index: 0;
        }

        .overlay, .overlay-1 {
          position: absolute;
          left: -6%;
          background: #fff;
          width: 180px;
          height: 50%;
          opacity: 1;
          border-right: 10px solid $nib-blue-color;
        }

        .overlay:before {
          content: '';
          position: absolute;
          top: 50%;
          bottom: 0;
          left: -50%;
          right: 0;
          transform: skewX(-20deg);
          width: 50%;
          height: 100%;
          background: #fff;
        }

        .overlay {
          top: 0;
          -webkit-transform: skewX(20deg);
          -ms-transform: skewX(20deg);
          transform: skewX(20deg);
        }

        .overlay-1 {
          bottom: 0;
          -webkit-transform: skewX(-20deg);
          -ms-transform: skewX(-20deg);
          transform: skewX(-20deg);
        }

        @include media-breakpoint-down(md) {
          .overlay, .overlay-1 {
            display: none;
          }
        }

      }

      .slider-text {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-align: center !important;
        align-items: center !important;

        @include media-breakpoint-up(md) {
          position: absolute;
          top: 0;
          left: 4%;
          bottom: 0;
          right: 0;
          width: 35%;
        }

        @include media-breakpoint-up(lg) {
          .text {
            position: absolute;
            top: 50%;
            left: 0;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 0;
            padding-left: 1rem;
          }
        }

        @include media-breakpoint-down(md) {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          padding: 1em;
        }

        .text {
          width: 100%;
        }
      }

      .text {
        z-index: 1;
      }

      .subheading {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: $nib-green-color;
      }

      h1 {
        font-size: 40px;
        color: $nib-green-color;
        line-height: 1.2;
        font-weight: 800;
        text-shadow: 2px 0 4px #f5f5f5;
        letter-spacing: 2px;
        text-transform: uppercase;
        word-break: keep-all;

        @include media-breakpoint-down(md) {
          color: #ffffff;
          text-shadow: none;
        }

        @include media-breakpoint-down(sm) {
          font-size: 30px
        }

        span {
          font-weight: 800;
          color: $nib-green-color;
        }
      }

      p {
        color: $nib-green-color;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.5;
      }
    }
  }

  .carousel-item.active,
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    z-index: 1;
    @include animationName(fadeIn);
  }

  &.animate-init:not(.animated-end) {

    .carousel-item {
      .slider-container .slider-text {
        opacity: 0;
        visibility: hidden;
        /*-webkit-animation-delay: .3s;
        animation-delay: .3s;
        @include animation(.5s);*/
      }

      /*&.active .slider-container .slider-text {
        @include animationName(fadeInUp);
      }*/
    }

    &.animate-start {
      .carousel-item {
        .slider-container .slider-text {
          -webkit-animation-delay: .3s;
          animation-delay: .3s;
          @include animation(.5s);
        }

        &.active .slider-container .slider-text {
          @include animationName(fadeInUp);
        }
      }
    }
  }

  /*&.animate-init {
    .carousel-item .slider-container .slider-text {
      -webkit-animation-delay: .3s;
      animation-delay: .3s;
      @include animation(.5s);
    }
  }*/

  .carousel-item.active .slider-container /* .slider-cover*/
  {
    @include animationName(zoomIn);
  }

  .active.carousel-item-left,
  .active.carousel-item-right {
    z-index: 0;
    @include animationName(fadeOut);
  }
}
