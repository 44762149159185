@import "variables";

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/carousel";
@import "../../../node_modules/bootstrap/scss/utilities";

html, body {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  font-weight: 400;
  background-color: inherit !important;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased
}

body a {
  outline: none !important;
  text-decoration: none;
  transition: .5s all;
  -webkit-transition: .5s all;
  -moz-transition: .5s all;
  -o-transition: .5s all;
  -ms-transition: .5s all
}

body img {
  max-width: 100%
}

a {
  color: $nib-blue-color
}

a:hover {
  text-decoration: none
}

input {
  transition: .5s all;
  -webkit-transition: .5s all;
  -moz-transition: .5s all;
  -o-transition: .5s all;
  -ms-transition: .5s all
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  color: #323648;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-rendering: optimizeLegibility;
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

ul {
  margin: 0;
  padding: 0
}

.bg-light {
  background-color: #f9fafd !important;
}

.container {

  @media (min-width: 992px) {
    max-width: 1280px;
  }

  @media (min-width: 1200px) {
    max-width: 1440px;
  }

  @media (max-width: 991px) {
    max-width: 100% !important;
  }
}


// new design
.nb-navbar {
  background: transparent !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;

  @mixin nb-navbar($marginTop: -78px) {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    margin-top: $marginTop;
    background: #fff !important;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 0 $grid-gutter-width / 2;
  }

  @include media-breakpoint-down(md) {
    @include nb-navbar(0);
  }

  @include media-breakpoint-up(lg) {

    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    &.scrolled {
      @include nb-navbar();


      &.awake {
        margin-top: 0;
        -webkit-animation-name: slideInDown;
        animation-name: slideInDown;
        /*-webkit-transition: .3s all ease-out;
        -o-transition: .3s all ease-out;
        transition: .3s all ease-out;*/
      }

      &.sleep {
        margin-top: 0;
        -webkit-animation-name: slideOutUp;
        animation-name: slideOutUp;
        /*-webkit-transition: .3s all ease-out;
        -o-transition: .3s all ease-out;
        transition: .3s all ease-out;*/
      }
    }
  }

  .u-hamburger__box {
    width: 1.5625rem;
    height: .875rem;
    display: inline-block;
    position: relative;
  }

  .u-hamburger__inner {
    display: block;
    top: .0625rem;
    margin: 0 -.0625rem;
  }

  .u-hamburger__inner, .u-hamburger__inner:after, .u-hamburger__inner:before {
    width: 1.5625rem;
    height: .125rem;
    background-color: #8c98a4;
    position: absolute;
    transition-property: transform, -webkit-transform;
    transition-duration: .3s;
    transition-timing-function: ease;
  }

  .u-hamburger__inner:after, .u-hamburger__inner:before {
    content: "";
    display: block;
  }

  .u-hamburger__inner:before {
    top: .375rem;
    transition-property: transform, opacity, -webkit-transform;
    transition-duration: .3s;
    transition-timing-function: ease;
  }

  .u-hamburger__inner:after {
    top: .75rem;
  }

  .u-hamburger:hover .u-hamburger__inner,
  .u-hamburger:hover .u-hamburger__inner:after,
  .u-hamburger:hover .u-hamburger__inner:before {
    background-color: $nib-blue-color;
  }

  .navbar-brand {
    color: #000000;
    padding-top: .7rem;
    padding-bottom: .7rem;
  }

  .navbar-toggler {
    border: none;
    cursor: pointer;
    outline: none !important;
    text-transform: uppercase;
    letter-spacing: .1em;

    font-size: inherit !important;
    line-height: 0;
    padding: .25rem !important;

    &:hover {
      .u-hamburger__inner, .u-hamburger__inner:after, .u-hamburger__inner:before {
        background-color: $nib-blue-color;
      }
    }

    .is-active .u-hamburger__inner, &[aria-expanded=true] .u-hamburger__inner {
      -webkit-transform: translate3d(0, .375rem, 0) rotate(45deg);
      transform: translate3d(0, .375rem, 0) rotate(45deg);
    }

    .is-active .u-hamburger__inner:before, &[aria-expanded=true] .u-hamburger__inner:before {
      -webkit-transform: rotate(-45deg) translate3d(-.22321rem, -.25rem, 0);
      transform: rotate(-45deg) translate3d(-.22321rem, -.25rem, 0);
      opacity: 0;
    }

    .is-active .u-hamburger__inner:after, &[aria-expanded=true] .u-hamburger__inner:after {
      -webkit-transform: translate3d(0, -.75rem, 0) rotate(-90deg);
      transform: translate3d(0, -.75rem, 0) rotate(-90deg);
    }
  }

  .navbar-nav {

    .nav-link {
      font-size: 14px;
      padding: .7rem 20px;
      color: rgba(0, 0, 0, .5);
      font-weight: 600;
      opacity: 1 !important;
      text-transform: uppercase;

      &:hover, &:focus {
        color: rgba(0, 0, 0, .7)
      }

      &.active {
        background: transparent;
        background: none;
        color: $nib-blue-color;
      }
    }
  }

  .btns-language {
    margin-left: 20px;
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0
    }

    a {
      background: #dadada;
      border-radius: 50%;
      color: #fff;
      padding: 6px 5px;
      font-size: 12px;

      &:first-child {
        margin-right: 10px
      }

      &:last-child {
        margin-right: 0
      }

      &.active {
        background: $nib-blue-color
      }

      &:focus:not(.active), &:hover:not(.active) {
        background: #008c3c;
        outline: none !important;
      }
    }
  }

  .navbar-panel {
    margin-left: auto !important;
    margin-right: auto !important;

    @include media-breakpoint-up(lg) {
      &.animate {
        -webkit-animation-duration: .3s;
        animation-duration: .3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;

        @-webkit-keyframes __fadeInUp {
          from {
            opacity: 0;
            -webkit-transform: translate3d(0, 50%, 0);
            transform: translate3d(0, 50%, 0);
          }

          to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
          }
        }

        @keyframes __fadeInUp {
          from {
            opacity: 0;
            -webkit-transform: translate3d(0, 50%, 0);
            transform: translate3d(0, 50%, 0);
          }

          to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
          }
        }

        @-webkit-keyframes __fadeOutDown {
          from {
            opacity: 1;
          }

          to {
            opacity: 0;
            -webkit-transform: translate3d(0, 50%, 0);
            transform: translate3d(0, 50%, 0);
          }
        }
        @keyframes __fadeOutDown {
          from {
            opacity: 1;
          }

          to {
            opacity: 0;
            -webkit-transform: translate3d(0, 50%, 0);
            transform: translate3d(0, 50%, 0);
          }
        }

        &.fadeInUp {
          -webkit-animation-name: __fadeInUp;
          animation-name: __fadeInUp;
        }

        &.fadeOutDown {
          -webkit-animation-name: __fadeOutDown;
          animation-name: __fadeOutDown;
        }
      }

      &.navbar-hide {
        display: none;
      }
    }
  }
}

@import "component/carousel";


.footer {
  position: relative;
  background: url("../images/DJI_0128-2 (1).jpg") no-repeat;
  padding: 100px 0 30px;
  background-size: cover;
  background-position-y: 78%;
  z-index: 0;


  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .5;
    background: linear-gradient(180deg, $nib-green-color, $nib-blue-color, $nib-blue-color);
    z-index: 1;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  @include media-breakpoint-down(md) {
    //background: $nib-blue-color;
    padding: 30px 0
  }

  &-title {
    font-size: 20px;
    letter-spacing: 1px;
    color: #fff;
  }

  .contact-info {
    h4 {
      font-size: 15px;
      letter-spacing: 1px;
      color: #fff;
    }

    p, p a {
      color: #fff;
      text-decoration-color: #fff !important;
      font-size: 15px;
    }
  }

  .nav a {
    color: #fff;
    text-decoration-color: #fff !important;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
  }

}

.feature-area {
  background-color: #ffffff;

  .section-title {
    padding-bottom: 75px;

    h2 {
      font-size: 42px;
      color: $nib-blue-color;
      margin-bottom: 20px;
    }

    p {
      color: rgba(0, 0, 0, 0.5);
      margin-bottom: 0;
      max-width: 300px;
    }
  }

  .feature-item {
    padding: 50px 40px;
    background: #f2f3f7;
    margin-bottom: 30px;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;

    &:hover {
      background: #fff;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .08);
    }

    h4 {
      margin-top: 30px;
      font-size: 18px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .container {
    padding-top: 5rem;
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}
